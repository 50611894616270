body {
  margin: 0px 0px 0px 1cm;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.7em;
}

body {
  background: rgb(255, 255, 255);
}

page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  overflow: hidden;
  width: 21cm;
  height: 29.7cm;
}

page[size="A4"] {
  width: 21cm;
  height: 29.7cm;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  text-align: left;
  letter-spacing: 2px;
  font-size: 3em;
  margin: 22px auto;
  margin-left: 14px;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  line-height: 1.5em;
}

th {
  background-color: rgb(211, 253, 211);
  font-size: 1em;
  height: 1.5em;
  text-align: center;
}

td {
  text-align: center;
}

td.num {
  color: rgb(50, 161, 50);
}

td.payday {
  color: red;
}

td.fee {
  color: rgb(1, 138, 202);
}

td.name {
  font-size: 1.1em;
  width: 3%;
}

th.rSender {
  font-size: 0.5em !important;
}

th.violin {
  font-size: 0.5em !important;
}

td.dob {
  font-size: 1.1em;
  width: 10%;
}

td.jBook {
  font-size: 1.1em;
  width: 6%;
}

td.attend {
  font-size: 1.1em;
  width: 7%;
}

td.rNumber {
  font-size: 1.1em;
  width: 16%;
}

td.violinOnly {
  color: rgb(25, 184, 25);
}

td.violinAttend {
  color: rgb(25, 184, 25);
  /*border-right-style: none;*/
}

tr.gap {
  background-color: rgb(255, 241, 253);
  height: 1px;
}

button {
  margin: 10px 2px;
  border-radius: 5px;
  border-width: 0px;
}

button.yellow {
  background-color: rgb(255, 255, 0);
}

button.gray {
  background-color: rgb(235, 235, 235);
}

@media print {
  .noPrint {
    display: none;
  }

  body,
  page {
    margin: 0;
    box-shadow: 0;
  }
}